import React, { memo } from "react";

import VideoPlayer from "../video/video.component";

import { Container } from "./short-promotion-video.styles";

const ShortPromotionVideo = memo(() => {
	return (
		<Container>
			<VideoPlayer
				//16: 9 video format
				srcVideo={
					"https://player.vimeo.com/external/807934746.m3u8?s=147e27c315af42bc305f1157cac81a12cb72febc"
				}
				autoPlay={true}
				aspectRatio={[9, 16]}
				controls={false}
				transitionDuration={0}
				muted={true}
				loop={true}
			></VideoPlayer>
		</Container>
	);
});

export default ShortPromotionVideo;
